<template>
  <div v-if='selectedContentSourceUrl' class='h-full'>
    <div style='position:relative; padding-bottom:56.25%; overflow:hidden;'>
      <iframe
        id='vodPlayer'
        name='vodPlayer'
        width='100%'
        height='100%'
        :src='`${selectedContentSourceUrl}${sourceUrlParams}`'
        frameborder='0'
        allowfullscreen
        allow='autoplay;fullscreen;'
        style='position:absolute;'>
      </iframe>
    </div>
  </div>
</template>

<script src= "//play.acs.wecandeo.com/html/utils/iframeAPI.js"></script>
<script>
import isEmpty  from 'lodash/isEmpty'

export default {
  name: 'VodContentWecandeo',
  props: [
    'showingContent',
    'autoplay', 
    'mute',
  ],
  computed: {
    sourceUrlParams () {
      let query = ''
      if (this.autoplay) {
        query = query + '&autoplay=true'
      }
      if (this.mute) {
        query = query + '&volume=0'
      }
      return query
    },
    hasShowingContent () {
      return !isEmpty(this.showingContent)
    },
    selectedContentSourceUrl () {
      if (this.hasShowingContent) {
        return this.showingContent.data.sourceUrl
      } else {
        return ''
      }
    }
  },
  methods: {
    initLiveVideoPlayer () {
      var iframe = document.getElementById('vodPlayer')
      var contents = iframe.contentWindow || iframe.contentDocument
      this.vodVideoPlayer = new smIframeAPI(contents)
      this.vodVideoPlayer.setName('vodPlayer')

      this.vodVideoPlayer.onEvent(smIframeEvent.FULLSCREEN, () => {
        console.log('fullscreen')
        this.fullscreenUpdate()
      })
    },
  },
  mounted () {
    this.initLiveVideoPlayer()
  }
}
</script>
